import React from 'react';

const DesktopIcon = ({className}) => (

    <svg width="30" height="27" viewBox="0 0 30 27" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.5 -0.000976562H4.5C3.30653 -0.000976562 2.16193 0.472901 1.31802 1.31641C0.474106 2.15992 0 3.30396 0 4.49686V16.4911C0 17.684 0.474106 18.828 1.31802 19.6715C2.16193 20.515 3.30653 20.9889 4.5 20.9889H13.5V23.9875H7.5C7.10218 23.9875 6.72064 24.1454 6.43934 24.4266C6.15804 24.7078 6 25.0891 6 25.4867C6 25.8844 6.15804 26.2657 6.43934 26.5469C6.72064 26.8281 7.10218 26.986 7.5 26.986H22.5C22.8978 26.986 23.2794 26.8281 23.5607 26.5469C23.842 26.2657 24 25.8844 24 25.4867C24 25.0891 23.842 24.7078 23.5607 24.4266C23.2794 24.1454 22.8978 23.9875 22.5 23.9875H16.5V20.9889H25.5C26.6935 20.9889 27.8381 20.515 28.682 19.6715C29.5259 18.828 30 17.684 30 16.4911V4.49686C30 3.30396 29.5259 2.15992 28.682 1.31641C27.8381 0.472901 26.6935 -0.000976562 25.5 -0.000976562ZM27 16.4911C27 16.8887 26.842 17.2701 26.5607 17.5512C26.2794 17.8324 25.8978 17.9904 25.5 17.9904H4.5C4.10218 17.9904 3.72064 17.8324 3.43934 17.5512C3.15804 17.2701 3 16.8887 3 16.4911V4.49686C3 4.09922 3.15804 3.71788 3.43934 3.43671C3.72064 3.15554 4.10218 2.99758 4.5 2.99758H25.5C25.8978 2.99758 26.2794 3.15554 26.5607 3.43671C26.842 3.71788 27 4.09922 27 4.49686V16.4911Z"
            fill="black" fillOpacity="0.5"/>
    </svg>


);

export default DesktopIcon;