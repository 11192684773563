import React from 'react';

const MetricsRoas = ({color, className}) => (

    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 13.3333C15.9167 13.3333 16.7017 13.0072 17.355 12.355C18.0083 11.7028 18.3344 10.9178 18.3333 10C18.3333 9.08333 18.0072 8.29889 17.355 7.64667C16.7028 6.99444 15.9178 6.66778 15 6.66667C14.0833 6.66667 13.2989 6.99333 12.6467 7.64667C11.9944 8.3 11.6678 9.08444 11.6667 10C11.6667 10.9167 11.9933 11.7017 12.6467 12.355C13.3 13.0083 14.0844 13.3344 15 13.3333ZM6.66667 30V26.6667H13.3333V21.5C11.9722 21.1944 10.7572 20.6183 9.68833 19.7717C8.61944 18.925 7.83444 17.8622 7.33333 16.5833C5.25 16.3333 3.50722 15.4239 2.105 13.855C0.702778 12.2861 0.00111111 10.4456 0 8.33333V6.66667C0 5.75 0.326667 4.96555 0.98 4.31333C1.63333 3.66111 2.41778 3.33444 3.33333 3.33333H6.66667V0H23.3333V3.33333H26.6667C27.5833 3.33333 28.3683 3.66 29.0217 4.31333C29.675 4.96667 30.0011 5.75111 30 6.66667V8.33333C30 10.4444 29.2983 12.285 27.895 13.855C26.4917 15.425 24.7489 16.3344 22.6667 16.5833C22.1667 17.8611 21.3822 18.9239 20.3133 19.7717C19.2444 20.6194 18.0289 21.1956 16.6667 21.5V26.6667H23.3333V30H6.66667ZM6.66667 13V6.66667H3.33333V8.33333C3.33333 9.38889 3.63889 10.3406 4.25 11.1883C4.86111 12.0361 5.66667 12.64 6.66667 13ZM23.3333 13C24.3333 12.6389 25.1389 12.0344 25.75 11.1867C26.3611 10.3389 26.6667 9.38778 26.6667 8.33333V6.66667H23.3333V13Z"
            fill="black"/>
    </svg>


);

export default MetricsRoas;