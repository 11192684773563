import React from 'react';

const EyeIcon = ({color, className}) => (

    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#D9D9D9" fillOpacity="0.6"/>
    </svg>


);

export default EyeIcon;