import React from 'react';
import axios from 'axios';
import GoogleCallback from "./GoogleAccountCallback";
import google from "../../modules/auth/assets/images/google.svg";
import {Button} from "@material-ui/core";
import GoogleLogoutButton from "../backend_login_with_google/GoogleLogOutButton";

const GoogleAccountConnectButton = () => {
    let baseUrl;
    baseUrl = process.env.REACT_APP_ADMIN_API
    const user = JSON.parse(localStorage.getItem('user'));
    const handleGoogleLogin = async () => {
        try {

            // Make a GET request to your Laravel API getAuthUrl endpoint
            const response = await axios.get(baseUrl+'/v1/google-account/get-login-url',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                });
            const authUrl = response.data;
            console.log(authUrl)

            // Redirect the user to the Google login page
            window.location.href = authUrl;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    };

    return (
        <div>
            {/*<h1>Login with Google</h1>*/}
            <Button
                onClick={handleGoogleLogin}
                color='secondary'
                variant='contained'
                className={'google-sign-in-btn'}
                fullWidth
            >
                <img src={google} alt="google" className={'google-icon'}/>
                &nbsp;
                Connect To Google Account
            </Button>
            {/*<button onClick={handleGoogleLogin}>Login with Google</button>*/}
            <GoogleCallback />
            {/*<GoogleLogoutButton />*/}
        </div>
    );
};

export default GoogleAccountConnectButton;