import React, {useEffect, useRef, useState} from 'react';
import LockIcon from "../../components/icons/LockIcon";
import {Button, TextField, Typography} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";

const ForgetPasswordModal = ({ isOpen, onClose }) => {
    const modalOverlayRef = useRef(null);
    const validationSchema = Yup.object().shape({
        forgetEmail: Yup.string().required('Username is required')
    });

    const [forgetEmail, setForgetEmail] = useState('');
    const formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit, formState} = useForm(formOptions);

    const onInputEmailChange = (e) => {
        setForgetEmail( e.target.value );
    };
    function onEmailSubmit({email, password}) {
        // console.log(email, password)
        // return dispatch(authActions.login({email, password}));
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalOverlayRef.current && !modalOverlayRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const signUp = () =>
    {

         // To Store Email in Localstore and send to Home Page

        if(forgetEmail === '')
        {
            alert('Email Field is empty')
        }
        console.log(forgetEmail)
        // axios.post('http://localhost:8000/api/v1/auth/sendVerificationCode',user)
        //     .then((response) => {
        //         if(response.status === 200){
        //             history.navigate('/verification-code')
        //         }
        //     })
        //     .catch((err) => {
        //         setMsg('Error in The Code');
        //     })
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-overlay" ref={modalOverlayRef}>
                <div className="modal-content forget-password-modal-content">
                    <div className="container forget-password-modal-container">
                        <LockIcon className={'forget-password-modal-icon'}></LockIcon>
                        <Typography className={'forget-password-modal-title-typography'}>
                            Forget Your Password ?
                        </Typography>
                        <Typography className={'forget-password-modal-subtitle-typography'}>
                            Confirm your email and we will send the instructions
                        </Typography>
                        <form onSubmit={handleSubmit(onEmailSubmit)}>
                            <Typography className={'forget-password-modal-email-label'}>
                                Email
                            </Typography>
                            <TextField
                                className={'forget-password-modal-email-input '}
                                {...register('forgetEmail')}
                                name="forgetEmail"
                                value={forgetEmail}
                                onChange={e => onInputEmailChange(e)}
                                placeholder='Enter You Email'
                                type='text'
                                fullWidth
                                variant="outlined"
                            />
                            <Button
                                type='submit'
                                onClick={signUp}
                                color='primary'
                                variant="contained"
                                className={'forget-password-modal-button'}
                                fullWidth>Send</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPasswordModal;