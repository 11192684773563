import { createSlice } from '@reduxjs/toolkit'

export const customerMetricsSlice = createSlice({
    name: 'customerMetrics',
    initialState: {
        value: '',
    },
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = 'metrics'
        },
        decrement: (state) => {
            state.value = ''
        },
        SetCustomerMetrics: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { increment, decrement, SetCustomerMetrics } = customerMetricsSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(SetCustomerMetrics(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.newCampName.value)`
export const customerMetricsValues = (state) => state.customerMetrics.value
export const customerMetricsDailyBudget = (state) => state.customerMetrics.value.averageDailyBudget
export const customerMetricsDailyDevice = function (state) {
    return state.customerMetrics.value.devices
}
export const allCustomerMetricsValues = function (state) {
    let metrics, mobileImpression = 0,desktopImpression= 0,tabletImpression= 0,
        mobilePercentage = 0 ,desktopPercentage = 0 ,tabletPercentage =0 ,devicesIndex = 0;
    metrics = [];
    if (state.customerMetrics.value !== ''){
        metrics['amountSpend'] = (state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.spend;
        }, 0)).toFixed(2)
        metrics['clicks'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.clicks;
        }, 0)
        metrics['impressions'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.impressions;
        }, 0).toFixed(2)
        metrics['average_cpc'] = (state.customerMetrics.value.metrics.reduce((total, item) =>
                item.metrics.average_cpc !== 0 ?
                    total + item.metrics.average_cpc : total, 0) /
            state.customerMetrics.value.metrics.filter(item => item.metrics.average_cpc !== 0).length).toFixed(2)
        metrics['average_cpm'] = (state.customerMetrics.value.metrics.reduce((total, item) =>
                item.metrics.average_cpm !== 0 ?
                    total + item.metrics.average_cpm : total, 0) /
            state.customerMetrics.value.metrics.filter(item => item.metrics.average_cpm !== 0).length).toFixed(2)
        metrics['ctr'] = ((state.customerMetrics.value.metrics.reduce((total, item) =>
                item.metrics.ctr !== 0 ?
                    total + item.metrics.ctr : total, 0) /
            state.customerMetrics.value.metrics.filter(item => item.metrics.ctr !== 0).length) * 100).toFixed(2)
        metrics['optimization_score'] = (state.customerMetrics.value.metrics.reduce((total, item) =>
                item.customer.optimization_score !== 0 ?
                    total + item.customer.optimization_score : total, 0) /
            state.customerMetrics.value.metrics.length).toFixed(2)
        metrics['conversions'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.conversions;
        }, 0).toFixed(2)
        metrics['conversions_rate'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.clicks;
        }, 0) > 0 ? (state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.conversions;
        }, 0) / state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.clicks;
        }, 0) * 100).toFixed(2) : 0
        metrics['cost_per_conversions'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.conversions;
        }, 0) > 0 ? (state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.cost_micros;
        }, 0) / state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.conversions;
        }, 0) ).toFixed(2) : 0
        metrics['conversions_value'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.conversions_value;
        }, 0).toFixed(2)
        metrics['roas'] = state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.cost_micros;
        }, 0) > 0 ? (state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.conversions_value;
        }, 0) / (state.customerMetrics.value.metrics.reduce((total, item) => {
            return total + item.metrics.cost_micros;
        }, 0)) * 100).toFixed(2) : 0

        state.customerMetrics.value.devices.map((function (device){

            if (device.segments.device === 'MOBILE'){
                mobileImpression = mobileImpression + device.metrics.impressions
            }else if (device.segments.device === 'DESKTOP'){
                desktopImpression = desktopImpression + device.metrics.impressions
            }else if (device.segments.device === 'TABLET'){
                tabletImpression = tabletImpression + device.metrics.impressions
            }
            const totatImpressions = mobileImpression + tabletImpression + desktopImpression;
            mobilePercentage = (mobileImpression / totatImpressions *100).toFixed(2);
            desktopPercentage = (desktopImpression / totatImpressions *100).toFixed(2);
            tabletPercentage = (tabletImpression / totatImpressions *100).toFixed(2);
            devicesIndex++
        }))
        metrics['mobilePercentage'] = mobilePercentage
        metrics['desktopPercentage'] = desktopPercentage
        metrics['tabletPercentage'] = tabletPercentage
        return metrics
    }else{
        return -1
    }

}
export const allCustomerMetricsSeries = function (state) {
    let series = [];
    let uniqueDates = []
    let transformedDateArray = []
    if (state.customerMetrics.value !== ''){
        const customerMetrics = state.customerMetrics.value.metrics
        const customerDevices = state.customerMetrics.value.devices
        series['date'] = customerMetrics.map(item => item.segments.date)
        series['spend'] = [{ name: 'Spends', data: (customerMetrics.map(item => item.metrics.spend.toFixed(2))) }]
        series['clicks'] = [{ name: 'Clicks', data: (customerMetrics.map(item => item.metrics.clicks)) }]
        series['cpc'] = [{ name: 'CPC', data: (customerMetrics.map(item => item.metrics.average_cpc.toFixed(2))) }]
        series['impressions'] = [{ name: 'Impressions', data: (customerMetrics.map(item => item.metrics.impressions)) }]
        series['cpm'] = [{ name: 'CPM', data: (customerMetrics.map(item => item.metrics.average_cpm.toFixed(2))) }]
        series['ctr'] = [{ name: 'CTR', data: (customerMetrics.map(item => (item.metrics.ctr * 100).toFixed(2))) }]
        const dates = customerDevices.map(entry => entry.segments.date);
        uniqueDates = [...new Set(dates)].sort();
        series['devices'] = [
            { name: 'MOBILE', data: Array(uniqueDates.length).fill(0) },
            { name: 'DESKTOP', data: Array(uniqueDates.length).fill(0) }
        ]
        customerDevices.forEach(entry => {
            const deviceIndex = series['devices'].findIndex(device => device.name === entry.segments.device);
            if (deviceIndex !== -1) { // Only process if the device is MOBILE or DESKTOP
                const dateIndex = uniqueDates.indexOf(entry.segments.date);
                series['devices'][deviceIndex].data[dateIndex] = entry.metrics.impressions;
            }
        });
        series['devicesDate'] = uniqueDates
        const spendData = customerMetrics.map(item => item.metrics.spend.toFixed(2));
        const clicksData = customerMetrics.map(item => item.metrics.clicks);
        const impressionsData = customerMetrics.map(item => item.metrics.impressions);
        const ctrData = customerMetrics.map(item => (item.metrics.ctr * 100).toFixed(2));
        transformedDateArray = [...new Set(dates)].sort();

        series['allMetricsSeries'] = [
            { name: 'Spend', data: spendData },
            { name: 'Clicks', data: clicksData },
            { name: 'impressions', data: impressionsData },
            { name: 'Ctr', data: ctrData },
        ];

        series['customMetricsSeries'] = [
            { name: 'Spend', data: spendData },
            { name: 'Clicks', data: clicksData },
        ];
        return series
    }else{
        return -1
    }

}

export default customerMetricsSlice.reducer
