import React, {useEffect, useState} from 'react';
import {
    Grid,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import axios from 'axios';

import google from '../../assets/images/google.svg';
import facebook from '../../assets/images/facebook.svg';
import logo from '../../assets/images/logo.png';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useSelector, useDispatch} from 'react-redux';
import { gapi } from 'gapi-script';
// import {history} from '../../helpers/history';
import {authActions} from '../../redux';
import  "../modal/ForgetPasswordModal";
import ForgetPasswordModal from "../modal/ForgetPasswordModal";
import GoogleCallback from "../../../../components/backend_login_with_google/GoogleCallback";
import GoogleLoginButton from "../../../../components/backend_login_with_google/GoogleLoginButton";


// export {Login};

function Login() {
    const [msg, setMsg] = useState('');
    const [user, setUser] = useState({
        email: '',
        password: '',
    });
    const [userErrors, setUserErrors] = useState({
        email: '',
        password: '',
    });
    const validateUser = (name, value) => {
        // Fields that require custom validation
        const fieldsWithCondition = ['email', 'password'];

        // Check if the field is one of the fields that require custom validation
        if (fieldsWithCondition.includes(name)) {
            // Custom validation logic for specific fields
            if (typeof value === 'string' && !value.trim()) {
                return 'This field is required.';
            }
            // You can add more specific conditions for each field if needed
        }

        // Add more validation conditions for other fields if necessary
        return '';
    };
    const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;

    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {email, password} = user;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleForgetPasswordClick = () => {
        setIsModalOpen(true);
    };



    const onInputChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
        if (authUser) window.location.href = '/'
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit, formState} = useForm(formOptions);

    function onSubmit() {
        const newErrors = {};
        Object.keys(user).forEach((key) => {

            const error = validateUser(key, user[key]);
            if (error) {
                newErrors[key] = error;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            // If there are errors, do not proceed to the next step
            setUserErrors(newErrors);
            return;
        }
        const email = user.email
        const password = user.password
        return dispatch(authActions.login({email, password}));
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientId,
                scope: 'profile email',
            });
        }

        gapi.load('client:auth2', start);
    }, []);

    const handleGoogleLogin = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn().then(googleUser => {
            const profile = googleUser.getBasicProfile();
            const id_token = googleUser.getAuthResponse().id_token;

            console.log('ID: ' + profile.getId());
            console.log('Name: ' + profile.getName());
            console.log('Image URL: ' + profile.getImageUrl());
            console.log('Email: ' + profile.getEmail());
            console.log('ID Token: ' + id_token);

            console.log(dispatch(authActions.loginWithGoogle({id_token}))) ;
            // Send the ID token to your backend for further processing
            // fetch(`${baseUrl}/v1/auth/google-login`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ token: id_token }),
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         console.log('Login successful:', data);
            //         // Handle successful login (e.g., save token, redirect user)
            //     })
            //     .catch(error => {
            //         console.error('Error during login:', error);
            //     });
        });
    };
    return (
        <Grid>
            <div className={'container  logo-container logo-container-mobile'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <Grid align='left' className={'logo-mobile'}>
                            <img src={logo} alt="google" className={'logo-image logo-image-mobile'}/>


                            <h4 style={{color: "green"}}>{msg}</h4>
                        </Grid>
                    </div>
                    <div className={'col-md-6 right left-mobile'}>
                        <Typography className={'have-account-typography'}> Don't have an account ?
                            <NavLink to="/register">
                                <span className={'login-header'}>Sign up</span>
                            </NavLink>
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-12 login-container-title'}>
                        <Typography className={'login-container-title-typography'}>
                            Login faster and safer to your Skyfy account
                        </Typography>
                        <Typography className={'login-container-subtitle-typography'}>
                            Next time we will remember how you would like to sign in so you can skip entering an email
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={'container login-form-container'}>
                <div className={'row'}>

                    <div className={'col-md-5'}>
                            <Typography className={'login-email-label'}> Email Address

                            </Typography>
                            <TextField
                                className={'login-email-input center-text '}
                                {...register('email')}
                                name="email"
                                value={email}
                                onChange={e => onInputChange(e)}
                                placeholder='Enter Your Email'
                                type='text'
                                fullWidth
                                variant="filled"
                                error={!!userErrors.email}
                                helperText={userErrors.email}
                            />
                            <Typography className={'login-email-label'}>
                                Password
                            </Typography>
                            <TextField
                                className={'login-email-input center-text '}
                                name="password"
                                value={password}
                                {...register('password')}
                                onChange={onInputChange}
                                placeholder="Enter Your Password"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                variant="filled"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handlePasswordVisibility}>
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!userErrors.password}
                                helperText={userErrors.password}
                            />
                            <FormControlLabel className={'login-checkbox-label'}
                                              control={
                                                  <Checkbox
                                                      checked={rememberMe}
                                                      onChange={handleRememberMeChange}
                                                      color="primary"
                                                  />
                                              }
                                              label={
                                                  <span className={'login-remember-label'}>Remember Me</span>
                                              }
                            />

                            <NavLink to="">
  <span className="login-forget-label" onClick={handleForgetPasswordClick}>
    Forget My password ?
  </span>
                            </NavLink>
                            <ForgetPasswordModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <Button
                                        type='submit'
                                        onClick={onSubmit}
                                        color='primary'
                                        variant="contained" className={'login-submit-button'}
                                        fullWidth>Continue</Button>

                                </div>
                                <div className="col-md-3"></div>

                            </div>
                            {authError &&
                                <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                            }
                    </div>
                    <div className={'col-md-2'}>
                        <div className="d-flex vertical-line">
                            <div className="vr"></div>
                        </div>
                    </div>
                    <div className={'col-md-5'} style={{alignSelf: 'center'}}>
                        <Button
                            type='submit'
                            onClick={handleGoogleLogin}
                            color='secondary'
                            variant='contained'
                            className={'google-sign-in-btn'}
                            fullWidth
                        >
                            <img src={google} alt="google" className={'google-icon'}/>
                            &nbsp;
                            Login with Google
                        </Button>
                        {/*<GoogleLoginButton />*/}
                        <Button
                            type='submit'
                            // onClick={signIn}
                            color='secondary'
                            variant='contained'
                            className={'facebook-sign-in-btn'}
                            fullWidth
                        >
                            <img src={facebook} alt="google" className={'facebook-icon'}/>
                            &nbsp;
                            Login with Facebook
                        </Button>
                    </div>
                </div>
            </div>


        </Grid>
    )
}

export default Login