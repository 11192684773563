import React from 'react';

const ContentIcon = ({color, className}) => (
    <svg width="35" height="44" viewBox="0 0 35 44" fill={color} className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 1.07294e-07C18.6866 -0.000242067 19.8368 0.409483 20.7559 1.15983C21.6751 1.91018 22.3068 2.95507 22.5441 4.11765H28.8235C30.4616 4.11765 32.0326 4.76838 33.191 5.92669C34.3493 7.08501 35 8.65602 35 10.2941V37.0588C35 38.6969 34.3493 40.2679 33.191 41.4263C32.0326 42.5846 30.4616 43.2353 28.8235 43.2353H6.17647C4.53837 43.2353 2.96736 42.5846 1.80905 41.4263C0.650734 40.2679 0 38.6969 0 37.0588V10.2941C0 8.65602 0.650734 7.08501 1.80905 5.92669C2.96736 4.76838 4.53837 4.11765 6.17647 4.11765H12.4559C12.6932 2.95507 13.3249 1.91018 14.2441 1.15983C15.1632 0.409483 16.3134 -0.000242067 17.5 1.07294e-07ZM20.4029 4.11765C20.1912 3.51684 19.7983 2.99652 19.2783 2.62847C18.7584 2.26042 18.137 2.06277 17.5 2.06277C16.863 2.06277 16.2416 2.26042 15.7217 2.62847C15.2017 2.99652 14.8088 3.51684 14.5971 4.11765H20.4029ZM6.17647 6.17647C5.0844 6.17647 4.03706 6.61029 3.26485 7.3825C2.49265 8.15471 2.05882 9.20205 2.05882 10.2941V37.0588C2.05882 38.1509 2.49265 39.1982 3.26485 39.9704C4.03706 40.7426 5.0844 41.1765 6.17647 41.1765H28.8235C29.9156 41.1765 30.9629 40.7426 31.7351 39.9704C32.5074 39.1982 32.9412 38.1509 32.9412 37.0588V10.2941C32.9412 9.20205 32.5074 8.15471 31.7351 7.3825C30.9629 6.61029 29.9156 6.17647 28.8235 6.17647H26.7647V12.3529H8.23529V6.17647H6.17647ZM10.2941 6.17647V10.2941H24.7059V6.17647H10.2941Z"
            />
    </svg>

);

export default ContentIcon;