import React from 'react';

const DollarIcon = ({color, className}) => (

    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="40.5" cy="40" rx="40.5" ry="40" fill="#FFE0B2" fillOpacity="0.6"/>
        <path
            d="M30 45.4H34.4C34.4 47.776 37.414 49.8 41 49.8C44.586 49.8 47.6 47.776 47.6 45.4C47.6 42.98 45.312 42.1 40.472 40.934C35.808 39.768 30 38.316 30 32.2C30 28.262 33.234 24.918 37.7 23.796V19H44.3V23.796C48.766 24.918 52 28.262 52 32.2H47.6C47.6 29.824 44.586 27.8 41 27.8C37.414 27.8 34.4 29.824 34.4 32.2C34.4 34.62 36.688 35.5 41.528 36.666C46.192 37.832 52 39.284 52 45.4C52 49.338 48.766 52.682 44.3 53.804V58.6H37.7V53.804C33.234 52.682 30 49.338 30 45.4Z"
            fill="#F8AA35"/>
    </svg>


);

export default DollarIcon;