import React, {useEffect, useState} from 'react';
import { DateRange } from 'react-date-range';
import { addDays, endOfMonth } from 'date-fns';
import { de } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file // custom css file for additional styling

function CustomCalendar({ dateRange, setDateRange, onDateRangeApply, handleCancel }) {
    const savedFormData = JSON.parse(localStorage.getItem('formData'));

    const [selectionRange, setSelectionRange] = useState({
        startDate: savedFormData !== null ? new Date(savedFormData.from) : new Date(),
        endDate: savedFormData !== null ? new Date(savedFormData.to) : addDays(new Date(), 7),
        key: 'selection',
    });
    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
    };

    const handleApplyClick = () => {
        onDateRangeApply(selectionRange);
    };


    const predefinedRanges = {
        'Today': {
            startDate: new Date(),
            endDate: new Date(),
        },
        'Yesterday': {
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        },
        'This Week': {
            startDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)),
            endDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)),
        },
        'Last Week': {
            startDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6)),
            endDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
        },
        'Last 7 days': {
            startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
            endDate: new Date(),
        },
        'Last 28 days': {
            startDate: new Date(new Date().setDate(new Date().getDate() - 28)),
            endDate: new Date(),
        },
        'Last 30 days': {
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date(),
        },
        'This Month': {
            startDate: new Date(new Date().setDate(1)),
            endDate: new Date(),
        },
        'Last Month': {
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1, 1)),
            endDate: new Date(new Date().setDate(0)),
        },
        'Last 90 days': {
            startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
            endDate: new Date(),
        },
        'Last 12 months': {
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            endDate: new Date(),
        },
        'Last calendar year': {
            startDate: new Date(new Date().getFullYear() - 1, 0, 1),
            endDate: new Date(new Date().getFullYear() - 1, 11, 31),
        },
        'This Year (Jan - today)': {
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(),
        },
    };

    const [rang,SetRange]=useState('')

    const handlePredefinedRange = (rangeKey) => {
        console.log(rangeKey)
        SetRange(rangeKey)
        const range = predefinedRanges[rangeKey];
        setSelectionRange({
            startDate: range.startDate,
            endDate: range.endDate,
            key: 'selection',
        });
    };

    return (
        <div className="custom-calendar-wrapper">
            <div className="custom-calendar">
                <div className="predefined-ranges">
                    {Object.keys(predefinedRanges).map((rangeKey) => (
                        <button
                            key={rangeKey}
                            className={`range-button ${rangeKey === rang ? 'active' : ''}`}
                            onClick={() => handlePredefinedRange(rangeKey)}
                        >
                            {rangeKey}
                        </button>
                    ))}
                </div>
                <div className="calendar-container">
                    <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        showMonthAndYearPickers={false}
                        months={12}
                        direction="vertical"
                        locale={de}
                        maxDate={endOfMonth(new Date())} // Set max date to the end of the current month
                    />
                </div>

            </div>
            <div className={'custom-calendar-buttons m-3 d-flex justify-content-end'}>
                <button onClick={handleCancel} className="custom-calendar-cancel-button m-2">Cancel</button>
                <button onClick={handleApplyClick} className={'custom-calendar-apply-button m-2'}>Apply</button>
            </div>

        </div>

    );
}

export default CustomCalendar;
