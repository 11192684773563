import {useSelector, useDispatch} from 'react-redux';
import {authActions} from '../redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/js/dist/dropdown';
import {Button, Typography} from "@material-ui/core";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Avatar} from 'primereact/avatar';
import {Toast} from "primereact/toast";
import {Menu} from "primereact/menu";
import axios from "axios";
import {SetGoogleAccount} from "../modules/campaign/redux/googleAccount";
import CustomCalendar from "./custom_calendar/custom_calendar";
import {DropdownMenu, DropdownToggle, Dropdown, ModalHeader, ModalBody, ModalFooter, Modal} from "reactstrap";
import {InputSwitch} from "primereact/inputswitch";
import GoogleAccountConnectButton from "./backend_connect_with_google/GoogleAccountConnectButton";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";



const Nav =({ formData, setFormData })=> {

    const navigate = useNavigate();
    useEffect(() => {
        const savedFormData = localStorage.getItem('formData');
        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
            setInput(
                new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long' }).format(formData.from === '' ?new Date() :new Date(formData.from)) +
                ' - ' +
                new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long' }).format(formData.to === '' ?new Date() :new Date(formData.to))
            );
        }
    }, [setFormData]);
    const [showCustomCalendar, setShowCustomCalendar] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });
    // Save data to localStorage whenever formData changes
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);
    const authUser = useSelector((x) => x.auth.user);
    const dispatch = useDispatch();
    // const [selectedOption, setSelectedOption] = useState(null);
    const [date, setDate] = useState(null);
    const [calendarVisible, setCalendarVisible] = useState(false);

    const [activeInput, setActiveInput] = useState(null);
    const [input, setInput] = useState('');
    const menuRight = useRef(null);
    const toast = useRef(null);
    const baseUrl = process.env.REACT_APP_ADMIN_API
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user?.user?.role;
    const googleUser = JSON.parse(localStorage.getItem('google_account'));
    const source = localStorage.getItem('source');
    const [is_connected, setIsConnected] = useState(source === 'google' ? false : true);
    if (!source) {
        localStorage.setItem('source','google');
    }

    const handleSwitchChange = (e) => {
        if (is_connected){
            localStorage.setItem('source','google');
        }else{
            localStorage.setItem('source','system');
        }

        setIsConnected(!is_connected)
        window.location.reload();
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [formattedDateRange, setFormattedDateRange] = useState({
        from: '',
        to: '',
    });


    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    function formatDateToYYYYMMDD(date) {

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateRangeApply = (range) => {
        const formattedFrom = formatDateToYYYYMMDD(range.startDate);
        const formattedTo = formatDateToYYYYMMDD(range.endDate);

        setFormattedDateRange({ from: formattedFrom, to: formattedTo });
        setFormData({ ...formData, from: formattedFrom, to: formattedTo  });
        setDateRange(range);
        toggleDropdown();
        window.location.reload();
    };
    const items = [
        {
            label: 'Account',
            icon: 'pi pi-user',
        },
        {
            separator: true
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                logout()
            }
        },
        googleUser ?{
            label: 'Disconnect From Google Account',
            icon: 'pi pi-sign-out',
            command: () => {
                disconnectFromGoogleAccount()
            }
        } : {
            label: 'Connect with Google Account',
            icon: 'pi pi-sign-out',
            command: () => {
                setModal(true)
            }
        },
        {
            label: 'Choose Account',
            icon: 'pi pi-sign-out',
            url : '/choose-customer'
        },
        {
            label: 'Download Scripts',
            icon: 'pi pi-download',
            url : '/download-script',
            target : 'blank'
        },
        {
            label: 'Report',
            icon: 'pi pi-file-export',
            url : '/report'
        },
        role && role?.length > 0 && role?.includes('admin') ?
            {
                label: 'Admin Report',
                icon: 'pi pi-file-export',
                url : '/admin-report'
            } : '',
            role && role?.length > 0 && role?.includes('admin') ?
            {
                label: 'Admin Sites Report',
                icon: 'pi pi-file-export',
                url : '/admin-agg-report'
            } : ''

    ];
    const handleSelectChange = (selectedOption) => {

        // setFormData({...formData, selectedOption: selectedOption});
        setFormData({ ...formData, selectedOption });
        if (selectedOption.value === '2' ||selectedOption.value === '3'){
            window.location.reload();
        }
    };

    const handleApply = () => {
        // Set the date range in the form inputs and hide the custom calendar
        setFormData({ ...formData, from: dateRange.startDate, to: dateRange.endDate });
        setShowCustomCalendar(false);
        toggleDropdown();
    };

    const handleCancel = () => {
        // Hide the custom calendar without changing the form inputs
        setShowCustomCalendar(false);
        toggleDropdown();
    };
    const onInputChange = (event) => {
        // Handle input changes
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const handleCalendarChange = (value) => {
        setDate(value);
        // Get the local time zone offset in minutes
        const timeZoneOffset = value.value.getTimezoneOffset() * 60000; // Convert to milliseconds
        // Adjust the date by the time zone offset and format it to 'YYYY-MM-DD'
        const formattedDate = new Date(value.value - timeZoneOffset).toISOString().split('T')[0];
        setFormData({ ...formData, [activeInput]: formattedDate });
        setCalendarVisible(false); // Hide calendar after date selection
    };



    const handleInputFocus = (inputName) => {
        setActiveInput(inputName);
        setCalendarVisible(true); // Show calendar when input is focused


    };

    const handleInputClick = (field) => {
        // setActiveInput(inputName);
        setShowCustomCalendar(!showCustomCalendar);
    };

    const logout = () => dispatch(authActions.logout());
    async function disconnectFromGoogleAccount() {
        try {

            // Make a GET request to your Laravel API getAuthUrl endpoint
            const response = await axios.post(baseUrl + '/v1/google-account/disconnect-from-account',
                { email: googleUser !== null ? googleUser.email : '' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                }).then((response) => {
                toast.current.show({severity: 'success', summary: 'Success', detail: 'Logged out from Google successfully'});
                localStorage.removeItem('google_account');
                localStorage.setItem('is_trying_to_connect_with_google', 0);
                localStorage.setItem('customer', null);
                dispatch(SetGoogleAccount([]))
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            }).catch((error) => {

                toast.current.show({severity:'error', summary: 'Error', detail: error.response.data.message});
                console.error('Error fetching data:', error.response.data.message);
                localStorage.setItem('is_trying_to_connect_with_google', 0);
                localStorage.setItem('google_account', null);
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);

            });
            const authUrl = response.data;

            // Redirect the user to the Google login page
            window.location.href = authUrl;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }
    const location = useLocation();
    const handleGoogleCallback = useCallback(async (authCode) => {
        localStorage.setItem('is_trying_to_connect_with_google', 1);

        try {
            const response = await axios.post(baseUrl + '/v1/google-account/connect-to-account', { auth_code: authCode }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const token = response.data;
            await localStorage.setItem('google_account', JSON.stringify(response.data.google_account));

            dispatch(SetGoogleAccount(response.data));

            // Ensure this line executes after the localStorage.setItem
            navigate('/choose-customer');
        } catch (error) {
            console.error(error);
            localStorage.setItem('is_trying_to_connect_with_google', 3);
        }
    }, [baseUrl]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const authCode = searchParams.get('code');

        if (authCode) {
            handleGoogleCallback(authCode);
        }
    }, [location.search, handleGoogleCallback]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    // only show nav when logged in
    if (!authUser) return null;
    return (
        <nav className="navbar navbar-expand navbar-dark bg-white header-nav">
            <div className="navbar-nav">
                <div className="row">
                    <div className={'col-md-12'}>
                        <div className="row filters-inputs-div">
                            <input
                                name="from"
                                type="text"
                                placeholder="10 October"
                                value={input}
                                disabled={true}
                                onClick={() => handleInputClick('from')}
                                onChange={onInputChange}
                                className="form-control inline-block w-9 filters-input"
                            />
                            {/*<Select*/}
                            {/*    id="mySelect"*/}
                            {/*    value={formData.selectedOption}*/}
                            {/*    onChange={handleSelectChange}*/}
                            {/*    options={options}*/}
                            {/*    placeholder=""*/}
                            {/*    classNamePrefix="custom-select"*/}
                            {/*    className={'filter-select form-control w-4'}*/}
                            {/*    components={{*/}
                            {/*        DropdownIndicator: () => <FontAwesomeIcon className={'select-icon'}*/}
                            {/*                                                  icon={faChevronDown}/>*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Dropdown className={'inline-block w-1 filters-date-picker'} isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle caret>
                                    <i className="pi pi-calendar" style={{ fontSize: '1.5rem' }}></i>
                                </DropdownToggle>
                                <DropdownMenu >
                                    <CustomCalendar
                                        dateRange={dateRange}
                                        setDateRange={setDateRange}
                                        onDateRangeApply={handleDateRangeApply}
                                        // handleApply={handleDateRangeApply}
                                        handleCancel={handleCancel}
                                        />
                                </DropdownMenu>
                            </Dropdown>

                                {/*<Calendar*/}
                                {/*    className="inline-block w-1 filters-date-picker"*/}
                                {/*    value={date}*/}
                                {/*    onChange={handleCalendarChange}*/}
                                {/*    showWeek*/}
                                {/*    // inline={true}*/}
                                {/*    showIcon*/}
                                {/*/>*/}
                            {/*<input type="text" placeholder={'10 '} className="form-control inline-block w-1 filters-input" />*/}
                        </div>


                    </div>

                </div>
            </div>
            {/*<div className={'source-switch d-flex justify-content-space-between'}>*/}
            {/*    <Typography className="mt-1">*/}
            {/*        G*/}
            {/*    </Typography>*/}

            {/*    <InputSwitch*/}
            {/*        className="datatable-switch inline-block m-1"*/}
            {/*        checked={is_connected === true}*/}
            {/*        onChange={handleSwitchChange}*/}
            {/*    />*/}
            {/*    <Typography className="mt-1">*/}
            {/*        S*/}
            {/*    </Typography>*/}

            {/*</div>*/}
            <div className="logout">

                <Typography className={'nav-username'}>{authUser.user.name}<br/>
                    <span>Premium</span>
                </Typography>
                <Toast ref={toast}></Toast>
                <Menu model={items} popup ref={menuRight} id="nav-popup_menu_right" popupAlignment="right"/>
                {/*<TieredMenu model={items} popup ref={menu}  breakpoint="767px" />*/}
                <Button onClick={(event) => menuRight.current.toggle(event)} className="mr-2 avatar-button"
                        aria-controls="popup_menu_right" aria-haspopup>
                    <Avatar label={authUser.user.name[0]} size="large"
                            style={{backgroundColor: 'rgba(5, 17, 242, 0.2)', color: 'rgba(5, 17, 242, 1)'}}
                            shape="circle"/>
                </Button>


            </div>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Connect To Google</ModalHeader>
                <ModalBody>
                    <GoogleAccountConnectButton  />
                </ModalBody>
                <ModalFooter>
                    {/*<Button color="primary" onClick={toggle}>*/}
                    {/*    Do Something*/}
                    {/*</Button>{' '}*/}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </nav>
    );
}

export default Nav;