import React from 'react';

const ConfigurationIcon = ({color, className}) => (
    <svg viewBox="0 0 42 42" fill='none' className={className} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.6832 27.6336C35.3099 27.5099 41 21.8636 41 14.3168C41 8.58677 39.1033 10.4835 37.1952 12.4144C35.3099 14.2787 31.488 18.1216 31.488 18.1216L23.8784 10.512C23.8784 10.512 27.7212 6.69008 29.5856 4.8048C31.5165 2.89669 31.5165 1 27.6832 1C20.1364 1 14.4672 6.69008 14.3664 14.3168C14.4463 16.1735 14.3664 20.024 14.3664 20.024C10.7804 23.6328 6.10999 28.3051 2.95201 31.4384C-2.62583 37.0391 4.96094 44.6258 10.5616 39.048C13.7006 35.8862 18.3862 31.2006 21.976 27.6336C21.976 27.6336 25.8265 27.5537 27.6832 27.6336Z"
             strokeWidth="2"/>
    </svg>


);

export default ConfigurationIcon;