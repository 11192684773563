import React from 'react';



const AuthRoutes = [
    {
        navLink: '/login',
        component: React.lazy(() => import('../pages/login/login')),
        authRequired: false
    },
    {
        navLink: '/register',
        component: React.lazy(() => import('../pages/register/register')),
        authRequired: false
    },
    {
        navLink: '/toc',
        component: React.lazy(() => import('../pages/create-account/toc')),
        authRequired: false
    },
    {
        navLink: '/authorization',
        component: React.lazy(() => import('../pages/authorization/authorization')),
        authRequired: false
    },
    {
        navLink: '/verification-code',
        component: React.lazy(() => import('../pages/verification-code/verification-code')),
        authRequired: false
    },
    {
        navLink: '/create-account',
        component: React.lazy(() => import('../pages/create-account/create-account')),
        authRequired: false
    },
    {
        navLink: '/basic-info',
        component: React.lazy(() => import('../pages/create-account/basic-info')),
        authRequired: false
    },
    {
        navLink: '/download-script',
        component: React.lazy(() => import('../pages/create-account/download_script')),
        authRequired: false
    },
];

export default AuthRoutes;