import React from 'react';

const MobileProgressIcon = ({className,progress}) => (

    <svg width="500" height="17" viewBox="0 0 500 17" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <rect y="17" width="17" height="500" rx="8.5" transform="rotate(-90 0 17)" fill="#D1D1D1" fillOpacity="0.3"/>
        <rect y="17" width="17" height={progress} rx="8.5" transform="rotate(-90 0 17)" fill="#29D683"/>
    </svg>


);

export default MobileProgressIcon;