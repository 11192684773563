import React from 'react';

const SmartAdviceIcon = ({color, className}) => (
    <svg width="40" height="32" viewBox="0 0 40 32" fill={color} className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 31.0783V27.7485H4.43976V3.5851C4.43976 2.59948 4.79124 1.75592 5.4942 1.05444C6.19568 0.351481 7.03924 0 8.02486 0H37.7379V2.21988H8.02486C7.62676 2.21988 7.2997 2.34789 7.04368 2.60392C6.78765 2.85994 6.65964 3.18701 6.65964 3.5851V27.7485H18.8246V31.0783H0ZM26.7673 31.0783C26.2582 31.0783 25.832 30.9066 25.4887 30.5633C25.1453 30.22 24.9736 29.7937 24.9736 29.2846V9.30573C24.9736 8.79812 25.1453 8.37264 25.4887 8.0293C25.832 7.68596 26.2582 7.51429 26.7673 7.51429H38.2086C38.7162 7.51429 39.1417 7.68596 39.485 8.0293C39.8283 8.37264 40 8.79812 40 9.30573V29.2846C40 29.7937 39.8283 30.22 39.485 30.5633C39.1417 30.9066 38.7162 31.0783 38.2086 31.0783H26.7673ZM27.1935 27.7485H37.7823V9.73417H27.1935V27.7485Z"
            />
    </svg>
);

export default SmartAdviceIcon;