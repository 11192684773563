import { createSlice } from '@reduxjs/toolkit'

export const systemAdsCampaignsSlice = createSlice({
    name: 'systemCampaigns',
    initialState: {
        value: [],
    },
    reducers: {
        increment: (state) => {
            state.value = 'metrics'
        },
        decrement: (state) => {
            state.value = ''
        },
        SetSystemAdsCampaigns: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { increment, decrement, SetSystemAdsCampaigns } = systemAdsCampaignsSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(SetSystemAdsCampaigns(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.newCampName.value)`
export const systemAdsCampaignsValues = (state) => state.systemAdsCampaigns.value

export const allSystemAdsCampaignsValues = function (state) {
    let campaigns
    campaigns = [];
    if (state.systemAdsCampaigns.value !== []){
        // console.log(state.campaigns.value.length)
        campaigns = state.systemAdsCampaigns.value



        return campaigns
    }else{
        return -1
    }

}

export default systemAdsCampaignsSlice.reducer
