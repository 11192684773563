import React from 'react';

const MetricsEye = ({color, className}) => (

    <svg width="45" height="28" viewBox="0 0 45 28" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M44.8706 13.3406C44.805 13.2033 43.2169 9.93763 39.6863 6.66506C34.9819 2.30453 29.04 0 22.5 0C15.96 0 10.0181 2.30453 5.31374 6.66506C1.78311 9.93763 0.187482 13.2085 0.129357 13.3406C0.0440693 13.5184 0 13.7108 0 13.9054C0 14.1 0.0440693 14.2924 0.129357 14.4702C0.194982 14.6075 1.78311 17.8714 5.31374 21.144C10.0181 25.5028 15.96 27.8073 22.5 27.8073C29.04 27.8073 34.9819 25.5028 39.6863 21.144C43.2169 17.8714 44.805 14.6075 44.8706 14.4702C44.9559 14.2924 45 14.1 45 13.9054C45 13.7108 44.9559 13.5184 44.8706 13.3406ZM22.5 25.0266C16.7287 25.0266 11.6869 23.0818 7.51311 19.2479C5.80057 17.6693 4.34359 15.8692 3.18748 13.9036C4.34328 11.9379 5.80029 10.1378 7.51311 8.55943C11.6869 4.7255 16.7287 2.78073 22.5 2.78073C28.2713 2.78073 33.3131 4.7255 37.4869 8.55943C39.2028 10.1374 40.6629 11.9375 41.8219 13.9036C40.47 16.2429 34.5806 25.0266 22.5 25.0266ZM22.5 5.56146C20.72 5.56146 18.9799 6.05072 17.4999 6.96737C16.0198 7.88402 14.8663 9.1869 14.1851 10.7112C13.5039 12.2356 13.3257 13.9129 13.6729 15.5311C14.0202 17.1494 14.8774 18.6358 16.136 19.8025C17.3947 20.9691 18.9984 21.7637 20.7442 22.0855C22.49 22.4074 24.2996 22.2422 25.9442 21.6108C27.5887 20.9794 28.9943 19.9102 29.9832 18.5383C30.9722 17.1665 31.5 15.5536 31.5 13.9036C31.4975 11.6919 30.5485 9.57134 28.8612 8.00737C27.1739 6.44341 24.8862 5.56376 22.5 5.56146ZM22.5 19.4651C21.3133 19.4651 20.1533 19.1389 19.1666 18.5278C18.1799 17.9167 17.4108 17.0482 16.9567 16.0319C16.5026 15.0157 16.3838 13.8975 16.6153 12.8187C16.8468 11.7398 17.4182 10.7489 18.2574 9.9711C19.0965 9.19332 20.1656 8.66364 21.3295 8.44905C22.4933 8.23446 23.6997 8.3446 24.7961 8.76553C25.8925 9.18646 26.8295 9.89929 27.4888 10.8139C28.1481 11.7284 28.5 12.8037 28.5 13.9036C28.5 15.3786 27.8679 16.7932 26.7426 17.8362C25.6174 18.8792 24.0913 19.4651 22.5 19.4651Z"
            fill="#54D5B6"/>
    </svg>


);

export default MetricsEye;