import React from 'react';

const MetricsConvValue = ({color, className}) => (

    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 32C25.2843 32 32 25.2843 32 17C32 8.71573 25.2843 2 17 2C8.71573 2 2 8.71573 2 17C2 25.2843 8.71573 32 17 32Z"
            stroke="#54D5B6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22Z"
            fill="#54D5B6" stroke="#54D5B6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


);

export default MetricsConvValue;