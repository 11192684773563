import { createSlice } from '@reduxjs/toolkit'

export const campaignMetricsSlice = createSlice({
    name: 'campaignMetrics',
    initialState: {
        value: '',
    },
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = 'metrics'
        },
        decrement: (state) => {
            state.value = ''
        },
        SetCampaignMetrics: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { increment, decrement, SetCampaignMetrics } = campaignMetricsSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(SetCampaignMetrics(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.newCampName.value)`
export const campaignMetricsValues = (state) => state.campaignMetrics.value
export const campaignMetricsDailyDevice = function (state) {
    return state.campaignMetrics.value.devices
}
export const allCampaignMetricsValues = function (state) {
    let metrics, mobileImpression = 0,desktopImpression= 0,tabletImpression= 0,
        mobilePercentage = 0 ,desktopPercentage = 0 ,tabletPercentage =0 ,devicesIndex = 0;
    metrics = [];
    if (state.campaignMetrics.value !== ''){
        metrics['amountSpend'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.spend;
        }, 0)).toFixed(2)
        metrics['clicks'] = state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.clicks;
        }, 0)
        metrics['impressions'] = state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.impressions;
        }, 0)
        metrics['average_cpc'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) =>
                item.average_cpc !== 0 ?
                    total + item.average_cpc : total, 0) /
            state.campaignMetrics.value.campaign_metrics.filter(item => item.average_cpc !== 0).length).toFixed(2)
        metrics['average_cpm'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) =>
                item.average_cpm !== 0 ?
                    total + item.average_cpm : total, 0) /
            state.campaignMetrics.value.campaign_metrics.filter(item => item.average_cpm !== 0).length).toFixed(2)
        metrics['ctr'] = ((state.campaignMetrics.value.campaign_metrics.reduce((total, item) =>
                item.average_cpm !== 0 ?
                    total + item.ctr : total, 0) /
            state.campaignMetrics.value.campaign_metrics.filter(item => item.ctr !== 0).length) * 100).toFixed(2)

        metrics['conversions'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.conversions;
        }, 0)).toFixed(2)
        metrics['conversions_rate'] = state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.clicks;
        }, 0) > 0 ? (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.conversions;
        }, 0) / state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.clicks;
        }, 0) * 100).toFixed(2) : 0
        metrics['cost_per_conversions'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.cost_per_conversion;
        }, 0)).toFixed(2)
        metrics['conversions_value'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.conversions_value;
        }, 0)).toFixed(2)
        metrics['roas'] = state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.cost_micros;
        }, 0) > 0 ? (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.conversions_value;
        }, 0) / (state.campaignMetrics.value.campaign_metrics.reduce((total, item) => {
            return total + item.cost_micros;
        }, 0) / 1e6) * 100).toFixed(2) : 0
        metrics['optimization_score'] = (state.campaignMetrics.value.campaign_metrics.reduce((total, item) =>
                item.optimization_score !== 0 ?
                    total + item.optimization_score : total, 0) /
            state.campaignMetrics.value.campaign_metrics.length).toFixed(2)


        return metrics
    }else{
        return -1
    }

}

export const allCampaignMetricsSeries = function (state) {
    let series = [];
    let uniqueDates = []
    if (state.campaignMetrics.value !== ''){
        const campaignMetrics = state.campaignMetrics.value.campaign_metrics
        series['date'] = campaignMetrics.map(item => item.date)
        const spendData = campaignMetrics.map(item => item.spend.toFixed(2));
        const clicksData = campaignMetrics.map(item => item.clicks);
        const impressionsData = campaignMetrics.map(item => item.impressions);
        const ctrData = campaignMetrics.map(item => (item.ctr * 100).toFixed(2));
        const dates = campaignMetrics.map(entry => entry.date);
        uniqueDates = [...new Set(dates)].sort();
        series['devicesDate'] = uniqueDates
        series['allMetricsSeries'] = [
            { name: 'Spend', data: spendData },
            { name: 'Clicks', data: clicksData },
            { name: 'impressions', data: impressionsData },
            { name: 'Ctr', data: ctrData },
        ];

        series['customMetricsSeries'] = [
            { name: 'Spend', data: spendData },
            { name: 'Clicks', data: clicksData },
        ];
        return series
    }else{
        return -1
    }

}

export default campaignMetricsSlice.reducer
