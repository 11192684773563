import React, {useState} from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChartWithoutLines = ({series,categories,color,height}) => {
    const chartOptions = {
        chart: {
            height: height,
            type: 'area',
            toolbar:{
                show:false,
            },
        },
        colors: color,
        grid: {
            show: true,      // you can either change hear to disable all grids
            xaxis: {
                lines: {
                    show: false  //or just here to disable only x axis grids
                }
            },
            yaxis: {
                lines: {
                    show: false  //or just here to disable only y axis
                }
            },
        },
        yaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },

        legend:{
            show: false,
        },
        xaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            type: 'category',
            categories: categories

        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm',
            },
        },
    };
    return (
        <div>
            <div id="chart">
                <ReactApexChart options={chartOptions} series={series} type={'area'} height={height} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ApexChartWithoutLines;
