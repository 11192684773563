import React, {createContext, useEffect, useState} from 'react';
import Sidebar from './sidebar';
import  Nav  from "../../components/Nav";
import MobileSidebar from "./mobileSidebar";

export const VerticalLayoutContext = createContext();

const AutomationLayout = ({ children }) => {
    function formatDateToYYYYMMDD(date) {

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [formData, setFormData] = useState(() => {
        const savedFormData = localStorage.getItem('formData');
        return savedFormData ? {
            selectedOption: { value: '1', label: 'Date Range' },
            from: JSON.parse(savedFormData).from === '' ? formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 30))) : JSON.parse(savedFormData).from,
            to: JSON.parse(savedFormData).to === '' ? formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))) : JSON.parse(savedFormData).to,
        } : {
            selectedOption: { value: '1', label: 'Date Range' },
            from: formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 30))),
            to: formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))),
        };
    });
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    return (
        <VerticalLayoutContext.Provider value={{ formData, setFormData}}>
            <div className="container-fluid">
                <div className="d-flex">
                    <Sidebar />
                    <MobileSidebar formData={formData} setFormData={setFormData} />
                    <main className="main-content p-3 main-content-automation">


                        {children}
                    </main>
                </div>
            </div>
        </VerticalLayoutContext.Provider>
    );
};

export default AutomationLayout;