import React, {Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';
import SystemWithGoogleHome from '../pages/home/system_with_google_home';
import VerticalLayout from '../pages/layout/vertical-layout';
import HorizontalLayout from '../pages/layout/HorizontalLayout';
import AuthRoutes from "../modules/auth/routes/auth-routes";
import CampaignRoutes from "../modules/campaign/routes/campaign-routes";
import Login from "../modules/auth/pages/login/login";
import Register from "../modules/auth/pages/register/register";
import AutomationLayout from "../pages/layout/automation-layout";
import {Navigate} from "react-router";


const NotFound = () => {
    return (
        <>
            <h1>404 Page Not Found</h1>
            <p>The requested page does not exist.</p>
        </>
    );
};

const AppRoutes = () => {
    const layout = 'vertical';

    const renderLayout = (layout, element) => {
        if (layout === 'vertical') {
            return <VerticalLayout>{element}</VerticalLayout>;
        } else if (layout === 'horizontal') {
            return <HorizontalLayout>{element}</HorizontalLayout>;
        }else if (layout === 'automation') {
            return <AutomationLayout>{element}</AutomationLayout>;
        }
        // Default to vertical layout if layout value is not recognized
        return <VerticalLayout>{element}</VerticalLayout>;
    };
    const isAuthenticated = localStorage.getItem('user') != null;

    const routes = [
        ...AuthRoutes,
        ...CampaignRoutes
    ]
    const unAuthorizedRoutes = [
        ...AuthRoutes
    ]
if (isAuthenticated)
    return (
        <Routes>
            {
                routes.map((route, index) => {
                    return (

                        <Route key={index} path={route.navLink} element={route.layout ? renderLayout(route.layout_file,<Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>)  : <Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>}/>

                    )
                })
            }
            <Route path="/home" element={renderLayout(layout, <SystemWithGoogleHome/>)}/>
            <Route path="/" element={renderLayout(layout, <SystemWithGoogleHome/>)}/>
            <Route path="*" element={<NotFound/>}/>
            <Route path="/404" element={<NotFound/>}/>
        </Routes>
    );
else
    return (
        <Routes>
            {
                unAuthorizedRoutes.map((route, index) => {
                    return (

                        <Route key={index} path={route.navLink} element={route.layout ? renderLayout(layout,<Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>)  : <Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>}/>

                    )
                })
            }
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
};

export default AppRoutes;